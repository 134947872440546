module.exports={
"label.account.name":`Prénom`,
"label.account.copy":`Veuillez copier le code de vérification aléatoire figurant dans l'email, puis cliquez sur le lien permettant de modifier le mot de passe à l'étape suivante.`,
"label.account.phone.length":`Le numéro de téléphone mobile doit comporter au moins 7 caractères`,
"label.account.Start.trial":`GET STRTED`,
"label.account.employees1000":`Plus de 1000 employés`,
"label.account.other.Ways":`Autres méthodes de connexion`,
"label.account.emailsync.button.cancel":`Annuler`,
"label.account.rule":`Constitué de 8 à 16 chiffres et lettres.`,
"label.account.password.update.success":`Le mot de passe a été changé avec succès.`,
"label.account.describe":`CloudCC se concentre sur la personnalisation numérique, l'intelligence analytique et la connectivité mondiale, et est personnalisé à la demande en même temps que le développement commercial des entreprises.`,
"label.account.enter.Emailbox":`Veuillez saisir votre adresse électronique.`,
"label.account.password":`Mot de passe`,
"label.account.problem":`La connexion a échoué.`,
"label.account.Complete.reset":`Courriel. Veuillez entrer le code de vérification pour terminer la réinitialisation du mot de passe.`,
"label.account.crm.used":`CRM utilisé par les sociétés cotées`,
"label.account.report.loading":``,
"label.account.notcustomer":`Pas de compte ?`,
"label.account.employees501":`5011000 employés`,
"label.account.title.operationManager":`Directeur des opérations`,
"label.account.Preset":`Outils de gestion de projet préétablis`,
"label.account.company":`Entreprise`,
"label.account.come":`Bienvenue sur la plateforme de réussite client de Cloudcc. Notre nouvelle plateforme éclair vous offre la solution la plus rapide et la plus complète, vous permettant de tout faire avec votre client au centre.  `,
"label.account.input.password":`Veuillez entrer votre mot de passe.`,
"label.account.emailobject.emaildetail.button.back":`Dos`,
"label.account.title.itManager":`Responsable informatique`,
"label.account.Modified.successfully":`Changement de mot de passe réussi. Veuillez cliquer à nouveau sur connexion pour retourner à la page de connexion.`,
"label.account.only11":`Veuillez saisir au moins 7 chiffres.`,
"label.account.signin":`Connexion`,
"label.account.register":`Inscrivezvous pour obtenir`,
"label.account.adopt":`Vérification réussie !`,
"label.account.title.hrManager":`Responsable marketing/RH`,
"label.account.input.usernames":`Veuillez entrer votre nom d'utilisateur.`,
"label.account.only.Numbers":`Le numéro de téléphone ne peut saisir que des chiffres.`,
"label.account.title.salesManager":`Directeur des ventes`,
"label.account.email":`Courriel :`,
"label.account.click":`Cliquez sur`,
"label.account.and":`et`,
"label.account.mobile":`Téléphone`,
"label.account.Privacy.policy":`Politique de confidentialité`,
"label.account.password.isnull":`Le mot de passe ne peut pas être vide.`,
"label.account.employees101":`101500 employés`,
"label.account.remember.name":`Mémoriser le nom d'utilisateur`,
"label.account.Select.country":`Veuillez sélectionner votre pays/région.`,
"label.account.distinguish":`Le mot de passe est sensible à la casse.`,
"label.account.fillMobile":`Veuillez entrer votre numéro de téléphone.`,
"label.account.Preconfigured":`Rapports et tableaux de bord préétablis`,
"label.account.Select.job":`Veuillez sélectionner votre poste.`,
"label.account.employees21":`21100 employés`,
"label.account.username":`Nom d'utilisateur :`,
"label.account.Submit":`Soumettre`,
"label.account.confirm":`Confirmation du compte`,
"label.account.contact":`Nous contacter`,
"label.account.country":`Pays`,
"label.account.username.isnull":`Le nom d'utilisateur ne peut pas être vide.`,
"label.account.forget.password":`Mot de passe oublié ?`,
"label.account.Loading":`Chargement`,
"label.account.goon":`Continuer`,
"label.account.Register.trial":`Inscrivezvous pour un essai gratuit`,
"label.account.title.other":`Autre`,
"label.account.surname":`Nom de famille`,
"label.account.Fail":`La vérification a échoué, veuillez réessayer.`,
"label.account.Terms.service":`Conditions d'utilisation`,
"label.account.Select.staff":`Veuillez sélectionner le nombre d'employés.`,
"label.account.username.enter":`Veuillez entrer votre nom de famille.`,
"label.account.trial":`Essai gratuit`,
"label.account.employees1":`1 à 20 employés`,
"label.account.Quick.registration":`ou accéder rapidement`,
"label.account.tabpage.ok":`Confirmer`,
"label.account.normal.reregister":`Connectezvous à nouveau`,
"label.account.batchadd.newbatchpage.save.em":`Le format de l'email est incorrect.`,
"label.account..user.title":`Titre du poste`,
"label.account.No.credit.card.required":`Aucune carte de crédit n'est requise.`,
"label.account.enterprise.Wechat":`WeCom`,
"label.account.sample.data":`Précharger les données échantillons`,
"label.account.title.hobbies":`Hobbies`,
"label.account.platform":`Nouvelle plateforme CRM intelligente, mobile et numérique`,
"label.account.sent":`Courriel envoyé`,
"label.account.Confirm.password":`Veuillez confirmer votre mot de passe.`,
"label.account.fill":`Veuillez remplir tous les champs.`,
"label.account.user.resetpw":`Réinitialiser le mot de passe`,
"label.account.Spell":`Faites glisser le curseur pour compléter le puzzle.`,
"label.account.identifying.code":`Veuillez entrer le code de vérification.`,
"label.account.staff":`Employés`,
"label.account.welcome":`Bienvenue à CloudCC`,
"label.account.online.guidance":`Conseil et formation en ligne`,
"label.account.login.CloudCC":`Connectezvous à CloudCC`,
"label.account.Company.name":`Veuillez saisir le nom de votre entreprise.`,
"label.account.title.generalManager":`Directeur général`,
"label.account.enter.user":`Pour réinitialiser votre mot de passe, veuillez saisir votre nom d'utilisateur CloudCC.`,
"label.account.sent.code":`Le code de vérification a été envoyé à`,
"label.account.pw.error.text":`Les deux mots de passe que vous avez saisis ne correspondent pas.`,
"label.account.title.customerManager":`Responsable du service clientèle`,
"label.account.label.captcha":`Code de vérification`,
"label.account.Trial.days":`Inscrivezvous pour un essai gratuit de 30 jours`,
"label.account.please.enter.name":`Veuillez saisir votre prénom.`,
"label.account.inspect":`Si vous ne recevez pas l'email de confirmation du code de vérification, veuillez vérifier le spam.`,
"label.account.User.rule":`Le nom d'utilisateur se présente sous la forme d'une adresse électronique.`,
"label.account.go.Login":`Vous avez déjà un compte ?`,
"label.account.Reading.consent":`Je suis d'accord avec le`,
"label.account.Change.Password2":`Changer le mot de passe`,
"label.account.Loginby":`Connexion par`,
"label.account.apply.partner":`Postuler pour devenir un partenaire CloudCC`,
"label.account.MobileLogin":`Mobile`,
"label.account.Registration":``,
"label.account.Italy":`Italie`,
"label.account.view.details":`Voir le détail`,
"label.account.accept":`Veuillez lire et accepter les conditions de service et la politique de confidentialité.`,
"label.account.China.Mainland":`Chine continentale`,
"label.account.NewPassword":`Veuillez entrer le nouveau mot de passe`,
"label.account.Mobile.not.empty":`Le numéro de mobile ne peut pas être vide`,
"label.account.Please.select":`Veuillez choisir`,
"label.account.market.introduce":`Une plateforme unifiée de canaux de marketing vous aide à gagner plus de prospects`,
"label.account.IOS":`Scanner pour télécharger l'application iOS`,
"label.account.failsend":`Échec de l'envoi`,
"label.account.UpperLowerlAphanumeric.character":`Les lettres majuscules et minuscules, les chiffres et les symboles sont requis.`,
"label.account.New.Password1":`Nouveau mot de passe`,
"label.account.succSend":`Un courriel vous a été envoyé. Veuillez copier le code de vérification contenu dans l'email et réinitialiser le mot de passe.`,
"label.account.Account.mobile":`Numéro de compte ou de portable`,
"label.account.Login.consent":`En vous connectant, vous acceptez de`,
"label.account.fillAreaCode":`Veuillez entrer l'indicatif régional`,
"label.account.active.login":``,
"label.account.jp":`Japon`,
"label.account.partner.success1":`Vous vous êtes inscrit avec succès en tant que partenaire CloudCC.`,
"label.account.Reset":`Réinitialiser`,
"label.account.no.user.info":``,
"label.account.EnterOldPass":`Veuillez entrer l'ancien mot de passe`,
"label.account.Germany":`Allemagne`,
"label.account.title.content":`Inscrivezvous à CloudCC | Essai gratuit de 30 jours  Essayez maintenant !`,
"label.account.Taiwan":`Taiwan, Chine`,
"label.account.China.Station":`Chine`,
"label.account.sale.introduce":`L'automatisation des ventes vous aide à remporter plus de contrats`,
"label.account.Back":`Étape précédente`,
"label.account.market":`Marketing Cloud`,
"label.account.section.company":`Détails de l'entreprise`,
"label.account.check.email":`notre responsable de compte vous contactera sous peu, veuillez vérifier votre courriel à temps.`,
"label.account.characters":`5 caractères`,
"label.account.International":`International`,
"label.account.britain":`RoyaumeUni`,
"label.account.reg.fail.msg":`L'inscription a échoué. Veuillez réessayer plus tard.`,
"label.account.success.reg":`vous vous êtes inscrit avec succès`,
"label.account.company.url":`Site web`,
"label.account.CannotBeEmpty":`Ne peut être vide`,
"label.account.phone.note":``,
"label.account.second":`{0} secondes plus tard`,
"label.account.Change.Password":`Changez votre mot de passe`,
"label.account.Netherlands":`PaysBas`,
"label.account.pleaseAgree":`Veuillez accepter les conditions d'utilisation et la politique de confidentialité.`,
"label.account.copyright":`CloudCC Inc. Tous droits réservés`,
"label.account.letter":`1 lettre`,
"label.account.remember":`Maintenant je me souviens`,
"label.account.accountNum":`Compte`,
"label.account.code.not.empty":`Le captcha ne peut pas être vide`,
"label.account.Spain":`Espagne`,
"label.account.Alphanumeric":`Un mélange de lettres et de chiffres est requis`,
"label.account.obtain":`Acquérir`,
"label.account.company.txt":`Veuillez ajouter votre nom, si vous n'avez pas d'entité commerciale enregistrée.`,
"label.account.con.browser":``,
"label.account.Android":`Scanner pour télécharger l'application Android`,
"label.account.title":`Essai gratuit de CloudCC  CloudCC.com`,
"label.account.partner.success2":`Nous vous contacterons dans un moment.`,
"label.account.Macao":`Macao, Chine`,
"label.account.Brazil":`Brésil`,
"label.account.service.introduce":`Un processus de service transparent améliore l'efficacité de la réponse`,
"label.account.codeFailure":`Code de vérification non valide`,
"label.account.AccountLogin":`Compte`,
"label.account.pswComplexReqRules":`La longueur minimale du mot de passe est {0}, et {1}`,
"label.account.UpperLowerlAphanumeric":`Les lettres majuscules et minuscules ainsi que les chiffres sont requis.`,
"label.account.active.username":``,
"label.account.rememberPassword":`Mémoriser le mot de passe`,
"label.account.HongKong":`Hon Kong, Chine`,
"label.account.oldPass":`Ancien mot de passe`,
"label.account.Data.storage":`Vos données seront stockées dans le centre de données {0}.`,
"label.account.number":`1 numéro`,
"label.account.Poland":`Pologne`,
"label.account.service":`Service Cloud`,
"label.account.Incorrect.format":`Format ou numéro incorrect, veuillez le saisir à nouveau.`,
"label.account.remember.status":`Rappelletoi de moi`,
"label.account.sale":`Cloud de vente`,
"label.account.No.account.binding":`Aucun compte n'est lié. Veuillez contacter l'administrateur`,
"label.account.Russia":`Russie`,
"label.account.France":`France`,
"label.account.partner.content":`CloudCC est une entreprise mondiale qui recrute actuellement des partenaires pour explorer des solutions dans différents secteurs. Venez rejoindre notre programme d'affiliation !`,
"label.account.reg.phone.already":`Cette organisation existe déjà pour ce téléphone. Connectezvous ou utilisez un autre téléphone pour vous inscrire.`,
"label.account.Confirm":`Confirmer le nouveau mot de passe`,
"label.account.VerificationLogin":`Vérifier le code`,
"label.account.USA":`ÉtatsUnis`,
"label.account.mobile.num":`Veuillez entrer le numéro de téléphone mobile`,
"label.account.MinLength":`La longueur minimale du mot de passe est`,
"label.account.change.your.password":`Veuillez changer le mot de passe maintenant pour des raisons de sécurité`,
"label.account.new.password":`Entrez un nouveau mot de passe pour {0}. Veillez à inclure au moins :`,
"label.account.website.enter":`Veuillez saisir l'adresse du site web officiel`,
"label.account.pswComplexIsZero":``
}